import favicon from "assets/img/favicons/favicon.png";
import faviconIsmael from "assets/img/favicons/favicon-ismael.png";
import faviconCenterbox from "assets/img/favicons/favicon-centerbox.png";
import faviconPinheiro from "assets/img/favicons/favicon-pinheiro.png";
import faviconTelemaco from "assets/img/favicons/favicon-telemaco.png";
import faviconFortali from "assets/img/favicons/favicon_fortali.png";
import favIconCompremax from "assets/img/favicons/favicon-compremax.png";
import favIconHiperQueiroz from "assets/img/favicons/favicon-hiperqueiroz.png";
import favIconWebMerc from "assets/img/favicons/favicon-webmerc.png";
import favIconAtacadaoOrganico from "assets/img/favicons/favicon-atacadaoorganico.png";
import favTestesExternos from "assets/img/favicons/favicon-testesexternos.png";
import favUniCompras from "assets/img/favicons/favicon-unicompras.png";
import favParanaense from "assets/img/favicons/favicon-paranaense.png";
import favMaitan from "assets/img/favicons/favicon-maitan.png";
import favGuarani from "assets/img/favicons/favicon-guarani.png";
import favDoca from "assets/img/favicons/favicon-doca.png";
import favExtrabom from "assets/img/favicons/favicon-extrabom.png";
import favMaenatureza from "assets/img/favicons/favicon-maenatureza.png";
import favSuperLagoa from "assets/img/favicons/favicon-superlagoa.png";
import favAlipio from "assets/img/favicons/favicon-alipio.png";
import favCasaChacon from "assets/img/favicons/favicon-casachon.png";
import favSaoBraz from "assets/img/favicons/favicon-saobraz.png";
import favPereira from "assets/img/favicons/favicon-pereira.png";
import favDonaDeCasa from "assets/img/favicons/favicon-donadecasa.png";
import favRamos from "assets/img/favicons/favicon-ramos.png";
import favItamarketApp from "assets/img/favicons/favicon-ItamarketApp.png";
import favVitoria from "assets/img/favicons/favicon-vitoria.png";
import favSouQuitanda from "assets/img/favicons/favicon-souQuitanda.png";
import favAzulao from "assets/img/favicons/favicon-azulao.png";
import faviconJuzen from "assets/img/favicons/favicon-juzan.png";
import faviconYroyak from "assets/img/favicons/favicon-yroyak.png";
import faviconCrisostomo from "assets/img/favicons/favicon_crisostomo.png";
import faviconBrasileiro from "assets/img/favicons/favicon_brasileiro.png";
import faviconCasaDoChocolate from "assets/img/favicons/favicon_casadochocolate.png";
import faviconSoares from "assets/img/favicons/favicon-soares.png";
import faviconBcAcougues from "assets/img/favicons/favicon-bcacougues.png";
import faviconHiperBem from "assets/img/favicons/favicon-hiperbem.png";
import faviconMercadoConvencao from "assets/img/favicons/favicon-mercadoconvencao.png";
import favIconVerdfrut from "assets/img/favicons/favicon-verdfrut.png";
import favIconSuperEconomia from "assets/img/favicons/favicon-supereconomia.png";
import faviconFazendinha from "assets/img/favicons/favicon-fazendinha.png";
import faviconSaoJudas from "assets/img/favicons/favicon-saojudas.png";
import faviconLuadiAlimentos from "assets/img/favicons/favicon-luadi.png";
import favIconPeti from "assets/img/favicons/favicon-peti.png";
import favIconBrasilGranel from "assets/img/favicons/favicon-brasilgranel.png";
import favIconAsiaEmporium from "assets/img/favicons/favicon-asia.png";
import favIconHiperBom from "assets/img/favicons/favicon-hiperbom.png";
import favIconRCenter from "assets/img/favicons/favicon-rcenter.png";
import favIconRiccoAlimentos from "assets/img/favicons/favicon-riccoalimentos.png";
import favIconAshConvenincia from "assets/img/favicons/favicon-ashconveniencia.png";
import favIconDaPraca from "assets/img/favicons/favicon-dapraca.png";
import favIconFonteDoChopp from "assets/img/favicons/favicon-fontedochopp.png";
import favIconSuperPortugal from "assets/img/favicons/favicon-superportugal.png";
import favIconSupermecadoVK from "assets/img/favicons/favicon-vk.png";
import favIconAtacadaoDoces from "assets/img/favicons/favicon-atacadao-dos-doces.png";
import favIconEssencialDrogaria from "assets/img/favicons/favicon-drogaria-essencial.png";
import favIconMoranguinho from "assets/img/favicons/favicon-moranguinho.png";
import favIconCasaMissao from "assets/img/favicons/favicon-casamissao.png";
import favIconSuperFacil from "assets/img/favicons/favicon-superfacil.png"
import favIconSuperNorberto from "assets/img/favicons/favicon-supernorberto.png";
import favIconSupermercadoTelefrango from "assets/img/favicons/favicon-supermercadotelefrango.png";
import favIconMercadinhoSaoLuiz from "assets/img/favicons/favicon-mercadinhosaoluiz.png";
import favIconMultimarket from "assets/img/favicons/favicon-multimarket.png";
import favIconPopularFarma from "assets/img/favicons/favicon-popularfarma.png";
import favIconExitoSupermercados from "assets/img/favicons/favicon-exito.png";
import favIconSuperCenter from "assets/img/favicons/favicon-supercenter.png";
import favIconFarmaciasFleming from "assets/img/favicons/favicon-farmaciasfleming.png";
import favIconXsteamSuplementos from "assets/img/favicons/favicon-xsteamsuplementos.png";
import favIconBorogodo from "assets/img/favicons/favicon-borogodo.png";
import favIconDrogariaFabiana from "assets/img/favicons/favicon-drogariafabiana.png";
import favIconPitStop from "assets/img/favicons/favicon-pitstopverao.png";
import favIconSuperCumbuco from "assets/img/favicons/favicon-supercumbuco.png";
import favIconFarmaciaBemEstar from "assets/img/favicons/faivcon-farmaciabemestar.png";
import favIconCrescerFarma from "assets/img/favicons/favicon-crescerfarma.png";
import favIconAdegaNewTrend from "assets/img/favicons/favicon-adeganewtrend.png";
import favIconEstalecasdoReal from "assets/img/favicons/favicon-estalecasdoreal.png";
import favIconTokleve from "assets/img/favicons/favicon-tokleve.png";
import favIconPropequeno from "assets/img/favicons/favicon-propequeno.png";
import favIconPlanetaConveniencia from "assets/img/favicons/favicon-planetaconveniencia.png"
import favIconPontomax from "assets/img/favicons/favicon-pontomax.png"
import favIconAraripe from "assets/img/favicons/favicon-araripe.png"
import favIconEconomico from "assets/img/favicons/favicon-economico.png"
import favIconChbDeposito from "assets/img/favicons/favicon-chbdeposito.png"
import favIconSupermercadoMateus from "assets/img/favicons/favicon-supermercadomateus.png"
import favIconKatucha from "assets/img/favicons/favicon-katucha.png"
import favIconCompreBem from "assets/img/favicons/favicon-comprebem.png"
import favIconEstacaoBebidas from "assets/img/favicons/favicon-estacaodebebidas.png"
import favIconSuperDiogenes from "assets/img/favicons/favicon-superdiogenes.png"
import favIconDuhDelivery from "assets/img/favicons/favicon-duhdelivery.png"
import favIconGraoVeg from "assets/img/favicons/favicon-graoveg.png"
import favIconNowNutricao from "assets/img/favicons/favicon-nownutricao.png"
import favIconWRBebidas from "assets/img/favicons/favicon-wrbebidas.png"
import favIconBarataoPalhoca from "assets/img/favicons/favicon-barataopalhoca.png"
import favIconRendeMais from "assets/img/favicons/favicon-rendemais.png"
import favIconCabanaDoAtleta from "assets/img/favicons/favicon-cabanadoatleta.png"
import favIconMinibox from "assets/img/favicons/favicon-minibox.png"
import favIconSuperEntrega from "assets/img/favicons/favicon-superentrega.jpeg"
import favIconLojaoDosCosmeticos from "assets/img/favicons/favicon-lojaodoscosmeticos.png"
import favIconZhouDelivery from "assets/img/favicons/favicon-zhoudelivery.png"
import favIconDrogariaBomPreco from "assets/img/favicons/favicon-drogariabompreco.png"
import favIconSuperMini from "assets/img/favicons/favicon-supermini.jpeg"
import favIconDrogaMaisPopular from "assets/img/favicons/favicon-drogamaispopular.png"
import favIconBaraoExpres from "assets/img/favicons/favicon-baraoexpress.png"
import favIconAdegaOsGasolinas from "assets/img/favicons/favicon-adegaosgasolina.png"
import favIconMixSupermercados from "assets/img/favicons/favicon-mixsupermercados.png"

import { getListMarkets } from "./helpers/apis/marketInfo";
import { darken, lighten } from "@material-ui/core";

export const SUPPORTED_CRM_COMPANYS = ["mercafacil", "zoombox", "izio"];
export const TEST_MODE = [
  "localhost",
  "mercadapp-staging.herokuapp.com",
  "superteste.mercadapp.com",
  "superstaging.mercadapp.com",
  "comprasonline.merconnect.com.br"
].includes(window.location.hostname);

export const root = () => {
  if (window.location.hostname === "superstaging.mercadapp.com") {
    return "https://staging.merconnect.com.br"
  }
  return "https://merconnect.mercadapp.com.br"
}
const payRoot = "https://www.merconnect-pay.com";

export const v4AuthHeader = {
  "Auth-Token":
    "RUsycjRnU1BLTndkblIyTnF1T3FvMGlnUDJKVWx4Nk95eC9IL0RaMU80dz0tLVl3dlBqUjJnK1p2amdheW9WRVlWM0E9PQ",
};
export function oAuthHeader() {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    let decrypted = window.aes.decrypt(accessToken, "mcSiteToken123");
    if (decrypted.sigBytes > 0) {
      decrypted = decrypted.toString(window.CryptoJS.enc.Utf8);
      return decrypted
    }
  }
  return null
}

export const oAuthData = {
  client_id: "dcdbcf6fdb36412bf96d4b1b4ca8275de57c2076cb9b88e27dc7901e8752cdff",
  client_secret:
    "27c92c098d3f4b91b8cb1a0d98138b43668c89d677b70bed397e6a5e0971257c",
  grant_type: "client_credentials",
};

const config = {
  currentLocale: "pt-BR",
  currentCurrency: "BRL",
  merconnectURLV4: root() + "/api/v4",
  merconnectURL: root() + "/api/v2",
  merconnectURLV1: root() + "/api/v1",
  merconnectPayURLV1: payRoot + "/api/v1",
  zoombox: root() + "/zoombox",
  mappURLV2: root() + "/mapp/v2",
  mappURLV3: root() + "/mapp/v3",
  impulseEventsUrl: "https://collect.chaordicsystems.com/v7/events/views",
};
/*
Configurações de aplicações Whitelabel
*/
const domains = [
  {
    brandId: 67,
    keywords: "",
    metaDescription: "Esse é um site teste hehe",
    name: "Mercado Teste",
    favicon: favicon,
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#FF9812",
    pixelId: "338101730597576",
    phonePrefix: "859",
    hosts: [
      "superteste.mercadapp.com",
      "superstaging.mercadapp.com",
      "comprasonline.merconnect.com.br",
      "localhost"
    ],
    onesignal: "fa10efe2-28d0-4267-92e9-d8f87da883fa",
    gaTrackingID: "UA-155058319-1",
    websiteMenu: false,
    hotjarID: "5156359",
  },
  {
    brandId: 10,
    keywords: "",
    metaDescription: "",
    favicon: faviconCenterbox,
    name: "Centerbox Supermercados",
    phonePrefix: "859",
    hosts: ["loja.centerbox.com.br"],
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#007600",
    onesignal: "c489cd58-be79-4135-abf5-26e1995faa8c",
    // safari_web_id: "web.onesignal.auto.694eada3-a476-4a33-8c61-99aa6c1d60b0"
  },
  {
    brandId: 51,
    keywords: "",
    metaDescription: "",
    favicon: faviconPinheiro,
    name: "Supermercado Pinheiro",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#D67C1B",
    phonePrefix: "859",
    onesignal: "b4f7fb0c-dfa7-475c-a0c3-038f238292b7",
    hosts: ["", "loja.pinheirosupermercado.com.br"],
  },
  {
    brandId: 70,
    keywords: "",
    metaDescription: "",
    hosts: ["loja.ismaelsupermercados.com.br"],
    name: "Ismael supermercados",
    favicon: faviconIsmael,
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#0069AD",
    onesignal: "1d4cd896-1bd8-488e-ad97-58432e932211",
    // safari_web_id: "web.onesignal.auto.01b20842-ed7c-48c4-bd42-e78491d78625"
  },
  {
    brandId: 19,
    keywords: "",
    metaDescription: "Não enfrente filas, faça suas compras on-line sem sair de casa pelo Meu Telêmaco. Pediu! chegou!",
    favicon: faviconTelemaco,
    name: "Meu Telêmaco",
    hosts: ["lojameutelemaco.com.br"],
    gtmId: "GTM-NWB4P23V",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#003477",
    pixelId: "895755454518623",
    onesignal: "857e2384-0a76-4310-a7e5-8ae9b2166110",
  },
  {
    brandId: 8,
    keywords: "",
    metaDescription: "",
    favicon: faviconFortali,
    name: "Fortali",
    hosts: ["armazemfortali.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#b20d15",
    onesignal: "55d3caa2-14fe-41ec-97ed-5c260c25cd12",
    gaTrackingID: "UA-116940606-4",
    gtmId: "G-Y33B1SMQNC"
  },
  {
    brandId: 6,
    keywords: "",
    metaDescription: "Compre Online no Supermercado Compremax, e receba em casa ou retire na loja, garanta entrega rápida de produtos frescos e mais! Compre pelo app ou nosso site, aproveite: rápido, fácil e confiável.",
    favicon: favIconCompremax,
    name: "Compremax",
    hosts: ["compremax.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#749915",
    onesignal: "d33c5835-d2c8-4fb4-ac83-491300d55331",
  },
  {
    brandId: 96,
    keywords: "",
    metaDescription: "",
    favicon: favIconHiperQueiroz,
    name: "Queiroz em Casa",
    hosts: ["delivery.hiperqueiroz.com.br", "www.queirozemcasa.com.br"],
    gtmId: "AW-11107456686",
    phonePrefix: "849",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#00aff5",
    onesignal: "b8c24b6b-e808-4975-af07-1a1f3fe10d0c",
  },
  {
    brandId: 169,
    keywords:
      "supermercado, atacadista, mercado, compra online, atacado, hipermercado",
    metaDescription:
      "Faça suas Compras Online sem sair de casa no MAITAN POCKET nova loja online da Rede Maitan Supermercados. É rápido, prático e confiável. Pague na entrega ou peça link para pagamento online. Acesse. Chega de Filas e Estacionamentos lotados no Mercado. Confira Ofertas EXCLUSIVAS em Ourinhos e Santa Cruz do Rio Pardo.",
    favicon: favMaitan,
    name: "Maitan Supermercados",
    gtmId: "AW-11507464871",
    hosts: ["compreonline.maitan.com.br"],
    phonePrefix: "559",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#000e4b",
    pixelId: "525215911353577",
    gaTrackingID: "UA-148367680-1",
    onesignal: "5fbf97f0-5d1c-4f8e-a3d1-50c71d7824b2",
  },
  {
    brandId: 153,
    keywords: "",
    metaDescription: "",
    favicon: favIconWebMerc,
    name: "Web Merc",
    hosts: ["webmerc.com.br"],
    phonePrefix: "779",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#6e0cd7",
    onesignal: "844a21e5-c155-43e5-94e8-4b9846a0e9ba",
  },
  {
    brandId: 50,
    keywords: "",
    metaDescription: "",
    favicon: favTestesExternos,
    name: "Testes Externos",
    hosts: ["teste.mercadapp.com"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#D67C1B",
    onesignal: "",
  },
  {
    brandId: 159,
    keywords: "",
    metaDescription: "",
    favicon: favParanaense,
    name: "Paranaense",
    hosts: ["loja.smparanaense.com.br"],
    phonePrefix: "669",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#451e0f",
    onesignal: "d2d84a71-4465-43f2-af25-263b3f391ab7",
  },
  {
    brandId: 160,
    keywords: "",
    metaDescription: "",
    favicon: favGuarani,
    name: "Super Guarani",
    pixelId: "215144580413200",
    hosts: ["loja.superguarani.com.br"],
    phonePrefix: "199",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#fe6e01",
    onesignal: "c53c3446-65ad-4983-9460-1f23e85b4e6a",
  },
  {
    brandId: 184,
    keywords: "",
    metaDescription: "",
    favicon: favDoca,
    name: "Na Doca tem",
    hosts: ["nadocatem.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#602918",
    onesignal: "bf4cc356-a834-40af-8e74-00ff7dbeb547",
  },
  {
    brandId: 84,
    keywords: "",
    metaDescription: "",
    favicon: favExtrabom,
    name: "Recibom",
    hosts: ["recibomdelivery.com.br", "www.recibomdelivery.com.br"],
    phonePrefix: "819",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#314692",
    pixelId: "449751106416158",
    onesignal: "9d47639f-5593-4299-827d-cd502869cb4e",
  },
  {
    brandId: 189,
    keywords: "",
    metaDescription: "",
    favicon: favMaenatureza,
    name: "Mãe Natureza",
    hosts: ["loja.maenatureza.com.br"],
    phonePrefix: "349",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#32CD32",
    onesignal: "478ca719-5f36-4a02-8711-fa1db2bcf22a",
  },
  {
    brandId: 25,
    keywords: "",
    metaDescription: "",
    favicon: favSuperLagoa,
    name: "Super Lagoa",
    gtmId: "G-QVB8FRWVRC",
    pixelId: "566104817645733",
    hosts: ["", "comprasonline.superlagoa.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#f9b129",
    onesignal: "7c13b037-a104-475a-aeff-6d610b66e467",
  },
  {
    brandId: 191,
    metaDescription: "",
    favicon: favAlipio,
    name: "Alípio em casa",
    hosts: ["alipioemcasa.com.br"],
    phonePrefix: "839",
    pixelId: "473682337168857",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#2764ae",
    keywords: [
      "supermercado pombal pb",
      "supermercados em pombal",
      "mercado pombal pb",
      "mercadinho pombal pb",
      "bodega pombal pb",
      "supermercado promoção pombal",
      "pombal supermercado",
      "supermercado promoção de hoje",
      "pombal bebidas pombal pb",
      "ração pombal pb",
      "carnes pombal pb",
      "feijoada pombal pb",
      "frios pombal pb",
      "pão pombal pb",
      "alipio em casa",
      "supermercado digital",
      "panetone pombal",
      "peru pombal pb",
      "chocolate pombal pb",
      "gelo pombal pb",
      "delivery pombal pb",
      "padaria pombal pb",
      "promoção pombal pb",
    ],
    onesignal: "dea88984-0dda-4b6a-9f99-98b3aefc480a",
  },
  {
    brandId: 197,
    keywords: "",
    metaDescription: "",
    favicon: favCasaChacon,
    name: "Casa Chacon",
    hosts: ["loja.casachacon.com.br"],
    phonePrefix: "819",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#72170F",
    pixelId: "2968894240005273",
    onesignal: "73ea5627-616f-4a05-9197-14837079bd66",
  },
  {
    brandId: 99,
    keywords: "",
    metaDescription: "",
    favicon: favSaoBraz,
    name: "São Braz Supermercado",
    hasCrm: false,
    hosts: ["saobrazdelivery.com.br"],
    phonePrefix: "869",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#1E3879",
    onesignal: "226715ca-e7ab-4a92-863e-392e1af3d0ac",
  },
  {
    brandId: 113,
    keywords: "",
    metaDescription: "",
    favicon: favPereira,
    name: "Pereira",
    hasCrm: false,
    hosts: ["loja.superpereira.com.br"],
    phonePrefix: "839",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#C52D30",
    onesignal: "0076daa1-d6d8-400d-8b89-a48d0e399be2",
  },
  {
    brandId: 139,
    keywords: "",
    metaDescription: "",
    favicon: favDonaDeCasa,
    name: "Dona de Casa",
    hasCrm: false,
    hosts: ["loja.donadecasasupermercados.com.br"],
    phonePrefix: "619",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#69A142",
    onesignal: "8bc9b593-4160-45e1-a3ab-ca736e67cadb",
  },
  {
    brandId: 215,
    keywords: "",
    metaDescription: "",
    favicon: favRamos,
    name: "Supermercado Ramos",
    hasCrm: false,
    hosts: ["loja.supermercadosramos.com.br"],
    phonePrefix: "279",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#2e1d86",
    onesignal: "7510b982-ab53-49bb-824e-9e65c13ff1e5",
  },
  {
    brandId: 190,
    keywords: "",
    metaDescription: "",
    favicon: favItamarketApp,
    name: "ItaMarket App",
    hasCrm: false,
    hosts: ["itamarketapp.com.br"],
    phonePrefix: "159",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#333333",
    onesignal: "4e402578-009f-41e3-a53f-dbd2bc072297",
    pixelId: "1005504953608940",
  },
  {
    brandId: 228,
    keywords: "",
    metaDescription: "",
    favicon: favVitoria,
    name: "Vitória Supermercados",
    hasCrm: false,
    hosts: ["vitoriasupervarejo.com.br"],
    phonePrefix: "929",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#0060A1",
    onesignal: "694ce662-8487-4849-8f2a-8bd8192529d9",
  },
  {
    brandId: 235,
    keywords: "",
    metaDescription: "",
    favicon: favSouQuitanda,
    name: "Sou Quitanda ",
    hasCrm: false,
    hosts: ["delivery.souquitanda.com.br"],
    phonePrefix: "279",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#d89531",
    onesignal: "c879f363-fdc0-4774-aa94-6783ed3aea35",
  },
  {
    brandId: 78,
    keywords: "",
    metaDescription: "",
    favicon: favAzulao,
    name: "Azulão Supermercados",
    hasCrm: false,
    hosts: ["loja.azulaosupermercados.com.br"],
    phonePrefix: "149",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#2570b0",
    onesignal: "e5782551-1667-4898-bc91-4261b7d2248b",
  },
  {
    brandId: 229,
    keywords: "",
    metaDescription: "",
    favicon: faviconJuzen,
    name: "Supermercado Juzan",
    hasCrm: false,
    hosts: ["juzanemcasa.com.br"],
    phonePrefix: "229",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#C80323",
    pixelId: "403285748166772",
    onesignal: "fbf2dedb-f4ae-4749-8ffc-171518141dcf",
  },
  {
    brandId: 194,
    keywords: "",
    metaDescription: "",
    favicon: faviconYroyak,
    name: "Supermercado Yroyak",
    hasCrm: false,
    hosts: ["yroyak.com.br"],
    phonePrefix: "929",
    pixelId: "5368553889890174",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#003c86",
    onesignal: "525063ce-6d27-45ce-a212-f548ea234ca8",
  }, {
    brandId: 283,
    keywords: "",
    metaDescription: "",
    favicon: faviconCrisostomo,
    name: "Supermercado Crisóstomo",
    hasCrm: false,
    hosts: ["supercrisostomo.com"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#14017c",
    onesignal: "aac7ac79-e569-4f64-b5c2-2ca2b37e095c",
  }, {
    brandId: 75,
    keywords: "",
    metaDescription: "",
    favicon: faviconBrasileiro,
    name: "Supermercado Brasileiro",
    hasCrm: false,
    hosts: ["loja.supermercadosbrasileiro.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#3c8837",
    onesignal: "0ddacc85-2226-4225-94d2-3fea3fe9d3fb"
  }, {
    brandId: 288,
    keywords: "",
    metaDescription: "",
    favicon: faviconCasaDoChocolate,
    name: "Casa do Chocolate",
    hasCrm: false,
    hosts: ["", "www.casadochocolate.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#5DC8FF",
    onesignal: "a95f95be-9bbd-4587-b256-400f2f173b55"
  }, {
    brandId: 311,
    keywords: "",
    metaDescription: "",
    favicon: faviconSoares,
    name: "Drogaria Soares",
    hasCrm: false,
    hosts: ["", "drogariasoares.mercadapp.com.br"],
    phonePrefix: "119",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#0280CD",
    onesignal: "068831f1-13ab-4497-aab8-2635a14cad2a"
  }, {
    brandId: 322,
    keywords: "",
    metaDescription: "",
    favicon: faviconBcAcougues,
    name: "B&C Açougues",
    hasCrm: false,
    hosts: ["", "bcacougues.com.br", "loja.bcacougues.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#BF0411",
    onesignal: "44c97f19-1fb9-407e-8717-7fa04895d828"
  }, {
    brandId: 362,
    keywords: "",
    metaDescription: "",
    favicon: faviconHiperBem,
    name: "Atacarejo Hiper Bem",
    hasCrm: false,
    hosts: ["hiperbem.com"],
    phonePrefix: "819",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#D72A2C",
    onesignal: "b740aae0-d820-4946-8d34-82dd7d2c2af8"
  }, {
    brandId: 367,
    keywords: "",
    metaDescription: "",
    favicon: faviconMercadoConvencao,
    name: "Mercado Convenção",
    hasCrm: false,
    hosts: ["", "loja.mercadoconvencao.com"],
    phonePrefix: "819",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#BF252E",
    onesignal: "c485aa9b-3504-4634-b0b9-6961225a00cd"
  }, {
    brandId: 349,
    keywords: "",
    metaDescription: "",
    favicon: favIconVerdfrut,
    name: "Verdfrut",
    fillsOffersScreen: true,
    hasCrm: false,
    hosts: ["", "loja.verdfrut.com.br"],
    phonePrefix: "819",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#00af00",
    onesignal: "334f12c4-c6d4-4018-81c2-e352aeef6a21"
  }, {
    brandId: 350,
    keywords: "",
    metaDescription: "",
    favicon: favUniCompras,
    name: "Unicompras Supermercado",
    hasCrm: false,
    hosts: ["", "unicomprassupermercado.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#0000fe",
    onesignal: "0fe96ecf-7d98-40e1-a2b8-5679abe6bfd1"
  },
  {
    brandId: 260,
    keywords: "",
    metaDescription: "",
    favicon: favIconAtacadaoOrganico,
    name: "Atacadão Orgânico",
    hasCrm: false,
    hosts: ["loja.atacadao-organico.com.br"],
    phonePrefix: "119",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#F27000",
    onesignal: "0d3a242e-e417-49ff-b06e-5271702b6c6f"
  },
  {
    brandId: 192,
    keywords: "",
    metaDescription: "",
    favicon: faviconFazendinha,
    name: "Fazendinha Supermercados",
    hasCrm: false,
    hosts: ["www.fazendinhasupermercados.com.br", "fazendinhasupermercados.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#00A858",
    onesignal: "e1b82639-2f58-4bd9-a5d2-3051ef1c4482"
  },
  {
    brandId: 91,
    keywords: "",
    metaDescription: "",
    favicon: faviconSaoJudas,
    name: "Supermercado São Judas Tadeu",
    hasCrm: false,
    hosts: ["supsjt.com.br", "www.supsjt.com.br"],
    phonePrefix: "639",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#008442",
    onesignal: "fd1b782a-39af-4948-8d30-40c2dfdc9cd0"
  },
  {
    brandId: 379,
    keywords: "",
    metaDescription: "",
    favicon: faviconLuadiAlimentos,
    name: "Luadi Alimentos",
    hasCrm: false,
    hosts: ["luadialimentos.com.br"],
    phonePrefix: "229",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#000000",
    onesignal: "27a01a9b-a339-4bb0-910a-a550e85cf0ad"
  },
  {
    brandId: 388,
    keywords: "",
    metaDescription: "",
    favicon: favIconSuperEconomia,
    name: "Super Juína",
    hasCrm: false,
    hosts: ["compre.superjuina.com.br"],
    phonePrefix: "669",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#212b39",
    onesignal: "02f7f5c6-055a-4606-bddc-99e43340f120"
  },
  {
    brandId: 384,
    keywords: "",
    metaDescription: "",
    favicon: favIconPeti,
    name: "Loja Peti",
    hasCrm: false,
    hosts: ["loja.peti.me"],
    phonePrefix: "219",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#d85203",
    onesignal: "ef07e87d-3e22-455e-ab69-9df675dcbdc9"
  },
  {
    brandId: 390,
    keywords: "",
    metaDescription: "",
    favicon: favIconBrasilGranel,
    name: "Brasil Granel",
    hasCrm: false,
    hosts: ["brasilagranel.mercadapp.com"],
    phonePrefix: "119",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#009C3B",
    onesignal: ""
  },
  {
    brandId: 397,
    keywords: "",
    metaDescription: "",
    favicon: favIconAsiaEmporium,
    name: "Ásia Emporium",
    hasCrm: false,
    hosts: ["", "asiaemporium.com.br"],
    phonePrefix: "319",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#000000",
    onesignal: "d1bb3ea0-1d4b-4307-88b5-ca079745c72e"
  },
  {
    brandId: 398,
    keywords: "",
    metaDescription: "",
    favicon: favIconHiperBom,
    name: "Hiper Bom",
    hasCrm: false,
    hosts: ["", "supermercadohiperbom.com.br"],
    phonePrefix: "819",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#fe0000",
    onesignal: "5d35170d-c989-4142-b64a-e04b5a0ab872"
  },
  {
    brandId: 37,
    keywords: "",
    metaDescription: "",
    favicon: favIconRCenter,
    name: "R Center Supermercados",
    hasCrm: false,
    hosts: ["rcentersupermercado.com.br"],
    phonePrefix: "859",
    pixelId: "752335573190420",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#FF8C00",
    onesignal: "27755262-14e1-4aaf-811f-6c8abd2b9f53"
  },
  {
    brandId: 742,
    keywords: "",
    metaDescription: "",
    favicon: favIconRiccoAlimentos,
    name: "Ricco Delivery",
    hasCrm: false,
    hosts: ["gruporiccoalimentos.com.br"],
    phonePrefix: "989",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#037040",
    onesignal: "b9ede1dd-fd84-489b-80a1-faf0a2d362dd"
  },
  {
    brandId: 739,
    keywords: "",
    metaDescription: "",
    favicon: favIconAshConvenincia,
    name: "Ash Conveniência",
    hasCrm: false,
    hosts: ["ashconveniencia.com"],
    phonePrefix: "439",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#039E3C",
    onesignal: "4f7e6bf9-06fa-4c83-ba9d-acbc0af17f48"
  },
  {
    brandId: 387,
    keywords: "",
    metaDescription: "",
    favicon: favIconDaPraca,
    name: "Da Praça Supermercado",
    hasCrm: false,
    hosts: ["dapracasupermercado.com.br"],
    phonePrefix: "759",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#E35F26",
    onesignal: "87637fe9-daf7-4c5a-9844-a3cd803f8778"
  },
  {
    brandId: 745,
    keywords: "",
    metaDescription: "",
    favicon: favIconFonteDoChopp,
    name: "Fonte do Chopp",
    hasCrm: false,
    hosts: ["loja.fontedochopp.com.br"],
    phonePrefix: "169",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#FFBB06",
    onesignal: "eb33b700-7cb4-4537-b6f4-a5cd22117c6d"
  },
  {
    brandId: 54,
    keywords: "",
    metaDescription: "",
    favicon: favIconSuperPortugal,
    name: "Super Portugal",
    hasCrm: false,
    hosts: ["loja.superportugalsupermercado.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#354489",
    onesignal: "37ffecc1-e45d-4664-908e-65d911b66e69"
  },
  {
    brandId: 741,
    keywords: "",
    metaDescription: "",
    favicon: favIconSupermecadoVK,
    name: "Supermercados VK",
    hasCrm: false,
    hosts: ["loja.supervk.com.br"],
    phonePrefix: "689",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#f7751c",
    onesignal: "9035495e-2de6-444f-a1f9-2dcb7c66ea06"
  },
  {
    brandId: 786,
    keywords: "",
    metaDescription: "",
    favicon: favIconAtacadaoDoces,
    name: "Atacadão dos Doces",
    hasCrm: false,
    hosts: ["", "atacadaodosdoces.online"],
    phonePrefix: "819",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#ED3637",
    onesignal: "e9c545bd-e0e3-455a-a0f3-0c6d3a90fc82"
  },
  {
    brandId: 785,
    keywords: "",
    metaDescription: "",
    favicon: favIconEssencialDrogaria,
    name: "Essencial Drogaria",
    hasCrm: false,
    hosts: ["essencialdrogaria.com.br"],
    phonePrefix: "319",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#2F6586",
    onesignal: "899e9b41-f275-44b6-8f2b-09e10120e340"
  },
  {
    brandId: 39,
    keywords: "",
    metaDescription: "",
    favicon: favIconMoranguinho,
    name: "Supermercado Moranguinho",
    hasCrm: false,
    hosts: ["loja.supermercadomoranguinho.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#FD3634",
    onesignal: "94fa63e6-b0b0-4ab4-a167-41fbae46e430"
  },
  {
    brandId: 783,
    keywords: "",
    metaDescription: "",
    favicon: favIconCasaMissao,
    name: "Casa Missão",
    hasCrm: false,
    hosts: ["casamissao.com.br", "www.casamissao.com.br"],
    phonePrefix: "119",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#65842e",
    onesignal: "7e6a8a4c-b14d-4ea1-bd16-b838a4258e98"
  },
  {
    brandId: 791,
    keywords: "",
    metaDescription: "",
    favicon: favIconSuperFacil,
    name: "Supermercado Fácil",
    hasCrm: false,
    hosts: ["www.supermercadosfacil.com.br", "supermercadosfacil.com.br"],
    phonePrefix: "119",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#1A2439",
    onesignal: "7ec22360-2be4-403b-809d-7485f8b5e125"
  },
  {
    brandId: 794,
    keywords: "",
    metaDescription: "",
    favicon: favIconSuperNorberto,
    name: "Super Norberto",
    hasCrm: false,
    hosts: ["supernorberto.com.br"],
    phonePrefix: "959",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#FF0000",
    onesignal: "43f81e5b-8663-4b63-a9d0-3b18f017836a"
  },
  {
    brandId: 52,
    keywords: "",
    metaDescription: "",
    favicon: favIconSupermercadoTelefrango,
    name: "Supermercado Telefrango",
    hasCrm: false,
    hosts: ["supertelefrango.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#EF7E08",
    onesignal: "22020a8d-e9b8-4658-a0a6-aa8dc78a6911"
  },
  {
    brandId: 221,
    keywords: "",
    gtmId: "GTM-PL2TJT6N",
    favicon: favIconMercadinhoSaoLuiz,
    name: "Mercadinhos São Luiz",
    hasCrm: false,
    hosts: ["mercadinhossaoluiz.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#f3A414",
    onesignal: "ce643dcd-6132-489e-8667-6704c528d8e7",
    reclameAquiId: "Mzc0NDY6bWVyY2FkaW5ob3Mtc2FvLWx1aXo=",
    hotjarID: "5274412"
  },
  {
    brandId: 802,
    keywords: "",
    metaDescription: "",
    favicon: favIconMultimarket,
    name: "Multimarket",
    hasCrm: false,
    hosts: ["multimarketcachoeiras.com.br"],
    phonePrefix: "219",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY2,
    color: "#d92525",
    onesignal: "1fa9ba5e-23dc-43ae-93e4-05862ad57b3b"
  },
  {
    brandId: 376,
    keywords: "",
    metaDescription: "",
    favicon: favIconPopularFarma,
    name: "Popular Farma",
    hasCrm: false,
    hosts: ["popularfarma.com"],
    phonePrefix: "919",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#ff0000",
    onesignal: "c52c5c4a-9e63-497b-8b41-2ec17388fde9"
  },
  {
    brandId: 56,
    keywords: "",
    metaDescription: "",
    favicon: favIconExitoSupermercados,
    name: "Êxito Supermercados",
    hasCrm: false,
    hosts: ["loja.exitosupermercados.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#343C89",
    onesignal: "6e4d9183-6a2e-4691-ac1d-b54a29f77e40"
  },
  {
    brandId: 801,
    favicon: favIconSuperCenter,
    name: "Super Center",
    hasCrm: false,
    hosts: ["supercentersupermercado.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#FCB713",
    onesignal: "d87b9693-1f34-450d-a5e1-9575f02308fe"
  },
  {
    brandId: 370,
    favicon: favIconFarmaciasFleming,
    name: "Farmácias Fleming",
    hasCrm: false,
    hosts: ["loja.fleming.com.br"],
    phonePrefix: "429",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#000066",
    onesignal: "8cbf3ef4-2bcc-4372-bb8b-f845769c464c"
  },
  {
    brandId: 812,
    favicon: favIconXsteamSuplementos,
    name: "XSTEAM Suplementos",
    hasCrm: false,
    hosts: ["suplementos.xsteam.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#000000",
    onesignal: "ff97e08b-4d02-49a7-b34b-92fb8b0f0164"
  },
  {
    brandId: 810,
    favicon: favIconBorogodo,
    name: "Borogodó",
    hasCrm: false,
    hosts: ["borogodoemporio.com.br"],
    phonePrefix: "199",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#DFB889",
    onesignal: "38c860c7-a210-4ebc-b0e3-1da8b6dd0fac",
    gtmId: "GTM-WX6XST86"
  },
  {
    brandId: 813,
    favicon: favIconDrogariaFabiana,
    name: "Drogaria Fabiana",
    hasCrm: false,
    hosts: ["compraonline.drogariafabiana.com.br"],
    phonePrefix: "319",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#269589",
    onesignal: "b241edf6-299e-468f-bdea-2ee52f12e967"
  },
  {
    brandId: 819,
    name: "Pit Stop Verão",
    favicon: favIconPitStop,
    color: "#F2B705",
    phonePrefix: "839",
    hosts: ["pitstopveraopb.com.br"],
    websiteMenu: true
  },
  {
    brandId: 245,
    favicon: favIconSuperCumbuco,
    name: "Super Cumbuco",
    hasCrm: false,
    hosts: ["comprasonline.supercumbuco.com"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#ec7640",
    onesignal: "8499d77c-2ab8-4f76-8e5b-63b9195a4f05"
  },
  {
    brandId: 820,
    favicon: favIconFarmaciaBemEstar,
    name: "Farmácia Bem Estar",
    hasCrm: false,
    hosts: ["starpharmacy.com.br"],
    phonePrefix: "649",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#1d4dbb",
    onesignal: "92ea6447-dec1-4598-b7ce-d364ac9920ea"
  },
  {
    brandId: 793,
    favicon: favIconCrescerFarma,
    name: "CrescerFarma",
    hasCrm: false,
    hosts: ["lojaonline.crescerfarma.com.br"],
    phonePrefix: "439",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY1,
    color: "#120a8f",
    onesignal: "ad67feec-d888-42a1-9cfc-29fc3c63e1e1"
  },
  {
    brandId: 823,
    favicon: favIconAdegaNewTrend,
    name: "Adega New Trend",
    hasCrm: false,
    hosts: ["adeganwt.com.br"],
    phonePrefix: "319",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#DB1F0A",
    onesignal: "074e2c27-8f02-400c-b928-4754a16d9385"
  },
  {
    brandId: 795,
    favicon: favIconEstalecasdoReal,
    name: "Estalecas do Real",
    hasCrm: false,
    hosts: ["compreonline.estalecasdoreal.com.br"],
    phonePrefix: "439",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#eacb30",
    onesignal: "5d419d37-9c92-4c3c-a676-69055aea23b5"
  },
  {
    brandId: 829,
    favicon: favIconTokleve,
    name: "Supermercado Tokleve",
    hasCrm: false,
    hosts: ["compraonline.smtokleve.com.br"],
    phonePrefix: "119",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#EF7917",
    onesignal: "e0ee8646-1af1-40ab-aeb8-c073f474b24a"
  },
  {
    brandId: 827,
    favicon: favIconPropequeno,
    name: "Propequeno",
    hasCrm: false,
    hosts: ["propequeno.com.br"],
    phonePrefix: "819",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#873E86",
    onesignal: "ef0a5cc7-8f20-4422-8f18-b330218283e7"
  },
  {
    brandId: 834,
    favicon: favIconPlanetaConveniencia,
    name: "PLANETA CONVENIENCIA",
    hasCrm: false,
    hosts: ["planetaconveniencia.com.br"],
    phonePrefix: "819",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#444444",
    onesignal: "095c7d41-748e-404e-912e-5e0b9f934286",
    pixelId: "908739501009902"
  },
  {
    brandId: 134,
    favicon: favIconPontomax,
    name: "PontoMax Atacado",
    hasCrm: false,
    hosts: ["www.pontomaxatacado.com.br"],
    phonePrefix: "999",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#fe0000",
    onesignal: "582a1ca7-3130-470a-8d44-e83ae0618465"
  },
  {
    brandId: 35,
    favicon: favIconAraripe,
    name: "Supermercado Araripe",
    hasCrm: false,
    hosts: ["supermercadoararipe.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#00791c",
    onesignal: "7e48f796-107d-4da8-b299-2b63322652cd"
  },
  {
    brandId: 316,
    favicon: favIconEconomico,
    name: "Super Mercadinho Econômico",
    hasCrm: false,
    hosts: ["supermercadinhoeconomico.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#1449a5",
    onesignal: "df554b42-6f70-4c1f-adda-fb6f4d4ffcc3",
    gtmId: "G-ZL51S7MMLN"
  },
  {
    brandId: 846,
    favicon: favIconChbDeposito,
    name: "CHB Depósito e Conveniência",
    hasCrm: false,
    hosts: ["lojaonline.chbdeposito.com.br"],
    phonePrefix: "919",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#fbdd12",
    onesignal: "0cda7200-84e3-4a00-b133-726604fbb3c0",
    websiteMenu: true
  },
  {
    brandId: 844,
    favicon: favIconSupermercadoMateus,
    name: "Supermercado Mateus",
    hasCrm: false,
    hosts: ["supmateus.com.br"],
    phonePrefix: "319",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#010100",
    onesignal: "d1036bf9-805f-49ad-a686-a46c28211aa4",
  },
  {
    brandId: 372,
    favicon: favIconKatucha,
    name: "Supermercado Katucha",
    hasCrm: false,
    hosts: ["supermercadokatucha.com.br"],
    phonePrefix: "119",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#184fb6",
    onesignal: "4248571f-57e3-4770-a4ab-eb3289133337",
  },
  {
    brandId: 840,
    favicon: favIconCompreBem,
    name: "Compre Bem",
    hasCrm: false,
    hosts: ["m-comprebemitaborai.com.br"],
    phonePrefix: "219",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#E0D909",
    onesignal: "f90d6cd7-ed71-46bb-bd50-a80ec736228d",
  },
  {
    brandId: 836,
    favicon: favIconEstacaoBebidas,
    name: "Estação de Bebidas",
    hasCrm: false,
    hosts: ["estacaodebebidas.com.br"],
    phonePrefix: "519",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#facc06",
    onesignal: "a6a1c4b0-8cd7-4a89-ab34-b1b0e7437472",
  },
  {
    brandId: 792,
    favicon: favIconSuperDiogenes,
    name: "Diógenes Supermercado",
    hasCrm: false,
    hosts: ["comprasonline.superdiogenes.com.br"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#D42124",
    onesignal: "298a57b6-ad50-4692-b362-279bf8fa79f0",
  },
  {
    brandId: 845,
    favicon: favIconDuhDelivery,
    name: "Duh Delivery",
    hasCrm: false,
    hosts: ["duhdelivery.com.br"],
    phonePrefix: "519",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#F99f25",
    onesignal: "ab728ca1-8b76-44e9-b7b6-b10e0a9cfd2d",
  },
  {
    brandId: 832,
    favicon: favIconGraoVeg,
    name: "Grão Veg Empório",
    hasCrm: false,
    hosts: ["graoveg.com.br"],
    phonePrefix: "119",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#F15e29",
    onesignal: "498fd13e-e78e-4a7b-ad57-dbf4563b436c",
  },
  {
    brandId: 852,
    favicon: favIconNowNutricao,
    name: "Now Nutrição",
    hasCrm: false,
    hosts: ["nownutricaoesportiva.com.br"],
    phonePrefix: "119",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#009838",
    onesignal: "3495fcd1-c7f4-4b9c-ab11-3bee0621689e",
    homeWithCommonCategories: true,
    noAlcoholicItemsSales: true,
    pixelId: "448111724695528"
  },
  {
    brandId: 857,
    favicon: favIconWRBebidas,
    name: "WR Bebidas",
    hasCrm: false,
    hosts: ["bevaremporium.com.br"],
    phonePrefix: "419",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#BFAB6E",
    onesignal: "3e240cb6-186a-4dca-999a-a9485531f1d9",
  },
  {
    brandId: 100,
    favicon: favIconBarataoPalhoca,
    name: "Baratão Palhoça",
    hasCrm: false,
    hosts: ["compre.barataoonline.com.br"],
    phonePrefix: "489",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#EB1B08",
    onesignal: "c796aa6f-fd2a-4350-b3bd-6e38f570d7fc",
    pixelId: "3395696177368214"
  },
  {
    brandId: 858,
    favicon: favIconRendeMais,
    name: "Grupo Rende Mais Supermercados",
    hasCrm: false,
    hosts: ["rendemais.net.br"],
    phonePrefix: "819",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#ed6d28",
    onesignal: "20ed0e8e-8144-41bf-9176-d43e2124f4c3",
  },
  {
    brandId: 855,
    favicon: favIconCabanaDoAtleta,
    name: "Cabana do Atleta",
    hasCrm: false,
    hosts: ["cabanadoatletabynow.com.br"],
    phonePrefix: "119",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#ecd81e",
    onesignal: "15969fd2-6742-4b51-84e1-babc7b611f5d",
  },
  {
    brandId: 69,
    favicon: favIconMinibox,
    name: "Super Minibox",
    hasCrm: false,
    hosts: ["lojaonline.superminibox.com"],
    phonePrefix: "859",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#00B85C",
    onesignal: "bcf54960-ad2c-4636-8e60-7b79ed52bb83",
  },
  {
    brandId: 861,
    favicon: favIconSuperEntrega,
    name: "Super Entrega",
    hasCrm: false,
    hosts: ["superentrega.net"],
    phonePrefix: "849",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#0058aa",
    onesignal: "92daa685-d04d-4009-b1ab-db6a509d9bb4",
  },
  {
    brandId: 859,
    favicon: favIconLojaoDosCosmeticos,
    name: "Lojão dos Comésticos",
    hasCrm: false,
    hosts: ["lojaodoscosmeticos.net.br"],
    phonePrefix: "819",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#873f9f",
    onesignal: "addda23c-7cf0-4516-968a-d3a4643ab90a",
  },
  {
    brandId: 849,
    favicon: favIconZhouDelivery,
    name: "Zhou Delivery",
    hasCrm: false,
    hosts: ["zhoudeliveryprime.com.br"],
    phonePrefix: "819",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#3ca4b4",
    onesignal: "864e40fe-a0bd-46f4-9dc8-ebe8de24d5d6",
  },
  {
    brandId: 851,
    favicon: favIconDrogariaBomPreco,
    name: "Drogaria Bom Preço",
    hasCrm: false,
    hosts: ["drogariabompreco.com.br"],
    phonePrefix: "449",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#12507b",
    onesignal: "836c1ff2-8616-4c39-9fda-f900fd2548d4",
  },
  {
    brandId: 766,
    favicon: favIconSuperMini,
    name: "Super Mini",
    hasCrm: false,
    hosts: ["superminicotegipe.com.br"],
    phonePrefix: "779",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#e41b21",
    onesignal: "0f242a4e-cfe7-47bd-8929-977455733fb5",
  },
  {
    brandId: 869,
    favicon: favIconDrogaMaisPopular,
    name: "Drogamais Popular",
    hasCrm: false,
    hosts: ["drogamaispopular.com.br"],
    phonePrefix: "439",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#f90101",
    onesignal: "ec6eac3d-c391-42ce-972f-4889151afdd4",
  },
  {
    brandId: 874,
    favicon: favIconBaraoExpres,
    name: "Barão Express",
    hasCrm: false,
    hosts: ["express.baraocervejas.com.br"],
    phonePrefix: "929",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#fdcd01",
    onesignal: "0be85eed-8368-4ff2-aba8-d61dc7d5505e",
  },
  {
    brandId: 876,
    favicon: favIconAdegaOsGasolinas,
    name: "Adega Os Gasolinas",
    hasCrm: false,
    hosts: ["loja.adegaosgasolinas.com"],
    phonePrefix: "119",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#000000",
    onesignal: "b1e7321c-a394-4a3d-987f-a9e8e6135288",
  },
  {
    brandId: 336,
    favicon: favIconMixSupermercados,
    name: "Mix Supermercados",
    hasCrm: false,
    hosts: ["mixsupermercados.shop"],
    phonePrefix: "829",
    recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY3,
    color: "#0f11ee",
    onesignal: "988d123d-54f2-4f78-be69-2c75132d4201",
  }
];

export const domainsBrandIds = domains.map((domain) => domain.brandId)

const findDomain = (function findDomain() {
  const localBrandID = localStorage.getItem("localBrandID")
  return domains.filter((domain) => {
    if ((localBrandID && TEST_MODE)) {
      return domain.brandId === Number(localBrandID);
    }
    return domain.hosts.includes(window.location.hostname);
  })[0];
})();

export function getDomainMarketColor() {
  return findDomain.color;
}
export function getRecaptchaSiteKey() {
  return findDomain.recaptchaSiteKey;
}
export function getOneSignalId() {
  return findDomain.onesignal;
}
export function getOneSignalIdSafari() {
  return findDomain.safari_web_id;
}
export function getPixelId() {
  return findDomain.pixelId;
}
export function getPhonePrefix() {
  return findDomain.phonePrefix;
}
export function getMenu() {
  return findDomain.websiteMenu
}
export function domainColorStyle(cssProperty) {
  return {
    [cssProperty]: getDomainMarketColor(),
  };
}
export function domainColorDarkStyle(cssProperty) {
  return {
    [cssProperty]: darken(getDomainMarketColor(), 0.15),
  };
}
export function domainColorLightStyle(cssProperty) {
  return {
    [cssProperty]: lighten(getDomainMarketColor(), 0.85),
  };
}
export function domainSecondaryColorStyle(cssProperty) {
  return {
    [cssProperty]: `${getDomainMarketColor()}66`,
  };
}
export function getDomainLogo() {
  const brandID = getBrandID();
  const logo = `${config.mappURLV2}/brands/${brandID}/logo`;
  return logo;
}
export function getDomainFavIcon() {
  return findDomain.favicon;
}
export function getDomainName() {
  return findDomain.name;
}
export async function checkMarketId() {
  const { data } = await getListMarkets();
  if (data) {
    return data;
  } else {
    return undefined;
  }
}
export function getDomainMarketId() {
  return localStorage.market_id;
}

export function getDomainGATrackingID() {
  return findDomain.gaTrackingID;
}

export function getBrandID() {
  return findDomain.brandId;
}

export function getDomainMetaDescription() {
  return findDomain.metaDescription;
}

export function getDomainKeywords() {
  return findDomain.keywords;
}

export function getDomainGTMId() {
  return findDomain.gtmId;
}

export function getFillsOffersScreen() {
  return findDomain.fillsOffersScreen;
}


export const getEbitStoreID = () => findDomain?.ebitStoreID

export function showCommonCategoriesOnHome() {
  return findDomain.homeWithCommonCategories;
}

export const getHotjarID = () => findDomain?.hotjarID;

export const isAlcoholRestricted = () => findDomain?.noAlcoholicItemsSales

export const getReclameAquiId = () => findDomain?.reclameAquiId;

export default config;
